<template>
  <div class="usage-table">
    <BaseTable :data="formatData" div class="setting-table">
      <el-empty
        v-show="formatData.length === 0"
        slot="empty"
        :description="text.noData"
        :image="require('@/assets/icon/nodata/no_data.svg')"
      />
      <BaseElTableColumn
        v-for="col in tableColumns"
        :key="col.name"
        :prop="col.prop"
        :label="col.label"
        :width="col.width"
        :fixed="col.fixed"
        :sortable="col.sortable"
        :show-overflow-tooltip="col.overflow"
        align="center"
      >
        <template slot-scope="scope">
          <component :is="col.is || 'span'" :type="scope.row.tagType">
            {{ scope.row[scope.column.property] }}
          </component>
        </template>
      </BaseElTableColumn>
    </BaseTable>
  </div>
</template>

<script>
import { text } from '@/constants'

const statusMap = {
  used: { type: 'danger', text: '已使用' },
  issued: { type: 'primary', text: '已發放' },
  expired: { type: 'info', text: '已過期' },
}

export default {
  name: 'UsageTable',
  props: {
    data: { type: Array, required: true },
  },
  data () {
    return {
      text,
      tableColumns: [
        {
          name: 'username',
          label: '會員',
          prop: 'name',
        },
        {
          name: 'phone',
          label: '電話號碼',
          prop: 'phone',
        },
        {
          name: 'ticketname',
          label: '活動名稱',
          prop: 'ticketname',
          overflow: true,
        },
        {
          name: 'usage',
          label: '使用狀態',
          prop: 'statusText',
          is: 'el-tag',
        },
      ],
    }
  },
  computed: {
    formatData () {
      return this.data.map(item => {
        return {
          ...item,
          ticketname: item.preferentialPlan.title,
          tagType: statusMap[item.status].type,
          statusText: statusMap[item.status].text,
        }
      })
    },
  },
  methods: {
    emitEvent (event, data) {
      this.$emit(event, data)
    },
  },
}
</script>

<style lang="scss">
.usage-table {
}
</style>
